import { useQuery } from '@tanstack/react-query';
import { getOnboarding } from './onboardingActions';

export const USER_ONBOARDING_QUERY_KEY = 'userOnboarding';

export const useUserOnboardingQuery = (enabled: boolean) => {
  return useQuery({
    queryKey: [USER_ONBOARDING_QUERY_KEY],
    queryFn: async () => getOnboarding(),
    staleTime: Infinity,
    enabled,
  });
};
