import { Avatar, Group, Menu, Text } from '@mantine/core';
import { signOut, useSession } from 'next-auth/react';
import { ApiUser } from '@/types/user';
import { clearCookies } from '@/app/proxy-server-cookie';
import { canUseAdminFeatures, urlHelper } from '@/utils';
import { DropdownMenuItem } from '@/app/components/navbar/DropdownMenuItem';
import externalLinks from '@/utils/externalLinks';
import { useDisclosure } from '@mantine/hooks';
import { ImpersonateUserModal } from './ImpersonateModal';
import { useImpersonationQuery } from '@/shared/useImpersonationQuery';
import { ImpersonationMenuItem } from './ImpersonationMenuItem';
import { Crisp } from 'crisp-sdk-web';

export function AccountPagesMenu() {
  const { data: session } = useSession();
  const [impersonationModalOpened, { open: impersonationModalOpen, close: impersonationModalClose }] =
    useDisclosure(false);
  const canUseAdmin = canUseAdminFeatures(session?.user);
  const { data: impersonation } = useImpersonationQuery();

  if (!session?.user) {
    return null;
  }

  const customSignOut = async () => {
    await signOut({ redirect: true });
    clearCookies();
    Crisp.setTokenId();
    Crisp.session.reset();
  };

  const userPlan = session?.user?.plan?.details?.id;
  const hasApiAccess = ![1, 60].includes(userPlan);
  const hasCliAccess = ![1, 60, 61, 62].includes(userPlan);

  return (
    <>
      <Group gap='xs' wrap='nowrap'>
        <Menu shadow='md' width={200}>
          <Menu.Target>
            <Group gap='xs' style={{ cursor: 'pointer' }}>
              <Avatar
                component='a'
                color={impersonation?.isImpersonating ? 'red.6' : 'gray.6'}
                style={{ cursor: 'pointer' }}
                src={impersonation?.isImpersonating ? null : session.user.avatar}
              >
                {getInitials(session.user, impersonation?.email)?.toLocaleUpperCase()}
              </Avatar>
              <Text size='sm' c={impersonation?.isImpersonating ? 'red.6' : 'gray.6'}>
                {impersonation?.email ?? session.user.email}
              </Text>
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <DropdownMenuItem link={urlHelper.settings()}>Settings & Upgrade Plan</DropdownMenuItem>
            <DropdownMenuItem link={urlHelper.usageStatistics()}>Usage Statistics</DropdownMenuItem>
            {hasCliAccess && (
              <>
                <DropdownMenuItem link={urlHelper.cli()}>CLI</DropdownMenuItem>
              </>
            )}
            {hasApiAccess && (
              <>
                <DropdownMenuItem link={urlHelper.webhooks()}>Webhooks</DropdownMenuItem>
                <DropdownMenuItem link={urlHelper.apiTokens()}>API Tokens</DropdownMenuItem>
              </>
            )}
            {canUseAdmin && <DropdownMenuItem link={urlHelper.admin()}>Admin Panel</DropdownMenuItem>}
            <ImpersonationMenuItem open={impersonationModalOpen} />
            <Menu.Item onClick={customSignOut}>Log out</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <ImpersonateUserModal opened={impersonationModalOpened} close={impersonationModalClose} />
    </>
  );
}

function getInitials(user: ApiUser, impersonatedEmail?: string) {
  if (impersonatedEmail) {
    return impersonatedEmail.split('@')?.[0].replace(/\./g, ' ').substring(0, 2);
  }
  const email = user.email.split('@')?.[0].replace(/\./g, ' ');
  return user.address.first_name ? `${user.address.first_name[0]}${user.address.last_name[0]}` : email?.substring(0, 2);
}
